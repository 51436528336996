.slideshow_img {
  width: 100%;
  height: 100%;
  max-height: 75vh;
  /* object-fit: cover; */
}

.ContentFullImage {
  width: 100%;
  height: 790px;
  max-height: 75vh;
  background-image: url("/team.jpg");
  background-size: auto 75vh;
  background-repeat: no-repeat;
  background-position: center top;
  /* background-size: auto; */
  background-color: rgb(219, 230, 238);
}

.team_img {
  border-radius: 25px;
  margin-left: 10px;
  max-width: 200px;
}

.centerConent {
  margin: auto;
  width: 80%;
}

.menuLink {
  text-decoration: inherit;
}

.activeMenuLink {
  background-color: lightblue;
}

.dividerSpace {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.indent {
  margin-left: 17px;
  margin-bottom: 10px;
}

.requestForm {
  background-color: #4dabf5;
  padding: 15px;
  border-style: solid;
  border-width: 2px;
  border-radius: 15px;
  border-color: blue;
}

.gutterBottom {
  margin-bottom: 10px;
}

.logoImg {
  height: 120px;
}

.desktopHeader {
}

.tabHolder {
}

.tab {
  text-decoration: none;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  /* Firefox */
  -moz-transition: all 0.2s ease-in;
  /* WebKit */
  -webkit-transition: all 0.2s ease-in;
  /* Opera */
  -o-transition: all 0.2s ease-in;
  /* Standard */
  transition: all 0.2s ease-in;
}

.tab:hover {
  background-color: blue;
  /* Firefox */
  -moz-transition: all 0.2s ease-in;
  /* WebKit */
  -webkit-transition: all 0.2s ease-in;
  /* Opera */
  -o-transition: all 0.2s ease-in;
  /* Standard */
  transition: all 0.2s ease-in;
}

.activeTab {
  background-color: blue;
}

.smallSpace {
  margin: 5px;
  padding: 5px;
}

.mobileMenuTab {
  text-decoration: none;
}

.visibleDropDownMenu {
  display: block;
}

.hiddenDropDownMenu {
  display: none;
}

@media screen and (max-width: 1000px) {
  .team_img {
    max-width: 20vw;
  }
}
